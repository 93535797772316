// script interface
import './../components/common_componentc/normalize/index'
import './../components/interface/grid/index'
import './../components/interface/button'


// script common elements
// import './../components/module/form_errors'

// script pages
import './../components/common_componentc/header/index'
import './../components/common_componentc/footer/index'
import './../components/pages/index/index'


