import './index.scss';




function check_active_input() {
    let wrap = $(this).parents('.form_profile');
    let all_input = $(wrap).find('.input_requared');

    let counter = 0;
    $.each(all_input, function(index, value){
        
        if ($(value).val() == '') {
            $(value).parents('.input_vak_wrap').find('.error').remove();
            $(value).parents('.input_vak_wrap')[0].appendChild(create_error('The field is required'));
        } else {
            $(value).parents('.input_vak_wrap').find('.error').text('');
            counter++;
        }
    });
    if ($(all_input).length == counter) {
        $(wrap).find('.form_img').addClass('form_img_active');
    } else {
        $(wrap).find('.form_img').removeClass('form_img_active');
    }
}

$('.input_requared').on('change', check_active_input);







$('.btn_for_add_form').on('click', function() {
    $('.form_create__block')[0].appendChild(create_first_block());
    $(this).parents('.form_profile').find('.form_img').removeClass('form_img_active');
})

function create_error(text) {
    let error = document.createElement('label');
    error.classList.add('error');
    error.setAttribute(`for`, 'code');
    error.setAttribute(`id`, 'code-error');
    error.textContent = text;
    return error;
}

function create_first_block() {

    let part__wrapper = document.createElement('div');
    part__wrapper.classList.add('part__wrapper');

    let input_vak_wrap_1 = document.createElement('div');
    input_vak_wrap_1.classList.add('input_vak_wrap');
    
    let input_form_1 = document.createElement('input');
    input_form_1.classList.add('input_form', 'input_requared', 'part_name');
    input_form_1.setAttribute(`type`, 'text');
    input_form_1.setAttribute(`name`, 'part_name');
    input_form_1.setAttribute(`placeholder`, 'Part name');

    let input_vak_wrap_2 = document.createElement('div');
    input_vak_wrap_2.classList.add('input_vak_wrap');

    let input_form_2 = document.createElement('input');
    input_form_2.classList.add('input_form', 'id');
    input_form_2.setAttribute(`type`, 'text');
    input_form_2.setAttribute(`name`, 'id');
    input_form_2.setAttribute(`placeholder`, 'id #');

    let label = document.createElement('div');
    label.classList.add('label_wrap', 'main__title', 'main__title_6', 'color_green');
    label.textContent = `* field is optional`;

    

    part__wrapper.appendChild(input_vak_wrap_1);
    input_vak_wrap_1.appendChild(input_form_1);
    part__wrapper.appendChild(input_vak_wrap_2);
    input_vak_wrap_2.appendChild(input_form_2);
    input_vak_wrap_2.appendChild(label);


    $(input_form_1).on('change', check_active_input);

    return part__wrapper;
}


$('.form_wrapper').on('submit', function (evt) {
    evt.preventDefault();
    let requare_inputs = $('.input_requared');
    let counter = 0;
    $.each(requare_inputs, function(index, value){
        
        if ($(value).val() == '') {
            $(value).parents('.input_vak_wrap').find('.error').remove();
            $(value).parents('.input_vak_wrap')[0].appendChild(create_error('The field is required'));
        } else {
            $(value).parents('.input_vak_wrap').find('.error').text('');
            counter++;
        }
    });
    if ($(requare_inputs).length == counter) {

        let another_block = $('.form_create__block').find('.part__wrapper');
        let another_array = [];
        $.each(another_block, function(index, value){
           let name = $(value).find('.part_name').val();
           let id = $(value).find('.id').val();
           another_array.push({
               'part_name': name,
               'id': id,
           })
        });
        
        let form_json = {
            'car_model': $('.car_model').val(),
            'marka': $('.marka').val(),
            'year': $('.year').val(),
            'vin code': $('.vin_code').val(),
            'name': $('.name').val(),
            'email': $('.email').val(),
            'phone': $('.phone').val(),
            'additional_information': $('.additional_information').val(),
            'another_parts': JSON.stringify(another_array),
        }

                



        console.log('form_json: ', form_json);
        let url_form = $('.form_wrapper').attr('action');
        console.log('url_form: ', url_form);
        fetch(url_form, {
            method: 'POST',
            body: new URLSearchParams($.param(form_json))
          })
          .then(data => {
            return data.json();
          })
          .then(data => {
              console.log(data)
            if(data.status=='OK' && typeof data['status'] !== "undefined"){
                $('.form_wrapper').addClass('form_wrapper_hidden'); 
                $('.form_wrapper').removeClass('form_wrapper_active'); 
                $('.access_form').addClass('access_form_active');  
                
                setTimeout(() => {
                    $('.form_wrapper').removeClass('form_wrapper_hidden'); 
                    $('.form_wrapper').addClass('form_wrapper_active'); 
                    $('.access_form').removeClass('access_form_active'); 
                }, 3000);
            } else if (data.status=='BAD' && typeof data['status'] !== "undefined") {
                // $(".error_block_false").text("Невірний логін або пароль");
              //   $.fancybox.open({
              //     src: '#modal-form_false',
              //   });

            }
          })
            
    }
});



